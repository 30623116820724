import { buildFacet } from "@coveo/headless";
import React, {  useEffect, useMemo, useState } from "react";
import { headlessEngine } from "../../coveo/Engine";
import { FacetValue } from "@coveo/headless/dist/definitions/controllers/facets/facet/headless-facet";
import { getAllFacets } from "./facet.utils";

type Props = {
  title: string;
  facetName: string;
};

export const Facet = ({ facetName }: Props) => {
  // @ts-ignore TS6133
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allFacets, setAllFacets] = useState<FacetValue[]>([]);

  const controller = useMemo(
    () =>
      buildFacet(headlessEngine, {
        options: {
          field: facetName,
          facetId: facetName,
        },
      }),
    [facetName],
  );
  const [state, setState] = useState(controller.state);

  useEffect(() => {
    getAllFacets({
      fields: "docsguide",
      onSuccess(results) {
        const data: FacetValue[] = [];
        results.forEach((result) => {
          if (result.value !== "Domino Documentation") {
            data.push({
              state: "idle",
              value: result.value,
              numberOfResults: result.numberOfResults,
            });
          }
        });
        setAllFacets(data);
      },
    });
    return controller.subscribe(() => setState(controller.state));
  }, [facetName]);


  return (
    <div className="flex flex-col justify-start items-start mx-8 mt-2 mb-1">
      {state.values.length === 0 && (
        <span className="facet-title">No filters available at the moment</span>
      )}
      {state.values.map((value, index) => {
        // TODO: remove when Coveo index is updated
        if (value.value === "Domino Documentation") {
          return;
        }
        return (
          <div className="flex flex-row items-center my-1.5" key={value.value}>
            
            <input
              className="w-3 h-3 bg-medium-dark-grey accent-medium-dark-grey"
              id={`_${facetName}-checkbox-${value.value}-${index}`}
              type="checkbox"
              checked={controller.isValueSelected(value)}
              onChange={() => controller.toggleSelect(value)}
              disabled={state.isLoading}
            />

            <label
              className="text-xs lg:text-sm cursor-pointer ml-1.5 checkbox-label"
              htmlFor={`_${facetName}-checkbox-${value.value}-${index}`}
            >
              {/*TODO: Same regarding TODO "hacks" comments*/}
              {value.value.includes("Api")
                ? value.value.replace("Api", "API")
                : value.value}{" "}
              ({value.numberOfResults} results)
            </label>
          </div>
        );
      })}
    </div>
  );
};
