import React, { useContext, useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { buildFacet } from "@coveo/headless";
import { headlessEngine } from "../../coveo/Engine";
import { SearchBoxContext } from "../../contexts/SearchBoxContext";
import { getAllFacets } from "./facet.utils";
import { FacetValue } from "@coveo/headless/dist/definitions/controllers/facets/facet/headless-facet";
import getFormatVersion from "../../utils/getFormatVersion";

type Props = {
  title: string;
  facetName: string;
};

const VersionPickerFacet = ({ facetName }: Props) => {
  // Subscribe to SearchBoxContext to know when searches happen
  const controller = useMemo(
    () =>
      buildFacet(headlessEngine, {
        options: {
          field: facetName,
          facetId: facetName,
          filterFacetCount: false,
          numberOfValues: 9999,
        },
      }),
    [facetName],
  );

  const {
    selectedVersion,
    setSelectedVersion
  } = useContext(SearchBoxContext);

  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(controller.state);
  const [allFacets, setAllFacets] = useState<FacetValue[]>([]);

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => setState(controller.state));
    return unsubscribe;
  }, [controller]);

  useEffect(() => {
    let mounted = true;

    getAllFacets({
      fields: "docsversion",
      onSuccess(results) {
        if (!mounted) return;

        const data: FacetValue[] = [];
        results.forEach((result) => {
          data.push({
            state: "idle",
            value: result.value,
            numberOfResults: result.numberOfResults,
          });
        });

        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

        setAllFacets(data.sort((a, b) => {
          const isANumber = /^\d/.test(a.value);
          const isBNumber = /^\d/.test(b.value);

          // If one is text and the other is a number, text comes first
          if (!isANumber && isBNumber) return -1;
          if (isANumber && !isBNumber) return 1;

          // Both are text
          if (!isANumber && !isBNumber) {
            return collator.compare(a.value, b.value);
          }

          // Both are numbers: Descending order
          return collator.compare(b.value, a.value);
        }));
      },
    });

    return controller.subscribe(() => setState(controller.state));

  }, []);

  const changeVersion = (value: string) => {
    if (value === 'all') {
      controller.deselectAll();
      setSelectedVersion(undefined);
    } else {
      const selectedFacet = getSortedVersions()[parseInt(value)];
      if (selectedFacet) {
        controller.toggleSingleSelect(selectedFacet);
        setSelectedVersion(selectedFacet);
      }
    }
  };

  function getSortedVersions(): FacetValue[] {
    const versions: FacetValue[] = [];
    allFacets.forEach((facet) => {
      const result = state.values.find((data) => data.value === facet.value);
      if (result) {
        versions.push({
          value: result.value,
          state: result.state,
          numberOfResults: result.numberOfResults,
        });
      } else {
        versions.push({
          value: facet.value,
          state: facet.state,
          numberOfResults: 0,
        });
      }
    });
    return versions;
  }

  function calculateTotalResults(versions: FacetValue[]): number {
    return versions.reduce((total, version) => total + version.numberOfResults, 0);
  }

  return (
    <div className="flex flex-col justify-start items-start text-white dark:text-white version-selection">
      {getSortedVersions().length > 0 && (
        <Select
          getPopupContainer={(trigger) => trigger.parentElement}
          disabled={state.isLoading}
          onChange={changeVersion}
          className="facet-version-select"
          value={selectedVersion ? String(getSortedVersions().findIndex(v => v.value === selectedVersion.value)) : 'all'}
          onDropdownVisibleChange={(visible) => setIsOpen(visible)}
        >
          <Select.Option key="all" value="all" className="p-2">
            {isOpen ? `All Versions (${calculateTotalResults(getSortedVersions())})` : 'All Versions'}
          </Select.Option>

          {getSortedVersions().map((v, index) => (
            <Select.Option
              disabled={v.numberOfResults === 0}
              key={index}
              className="p-2"
              value={String(index)}
            >
              {getFormatVersion(v.value)}{" "}
              {isOpen && v.numberOfResults !== 0 && `(${v.numberOfResults})`}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};
export default VersionPickerFacet;
