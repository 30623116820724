import { buildSearchEngine, getOrganizationEndpoints } from "@coveo/headless";

export const COVEO_ACCESS_TOKEN = "xx7e552044-7298-45f6-8dee-95830c606411";
export const COVEO_ORGANISATION_ID = "dominodatalabproductionxytu5nbs";
export const COVEO_SOURCE = "Domino Documentation";

// TODO: put access token into .env
export const headlessEngine = buildSearchEngine({
  configuration: {
    organizationId: COVEO_ORGANISATION_ID,
    accessToken: COVEO_ACCESS_TOKEN,
    organizationEndpoints: getOrganizationEndpoints(COVEO_ORGANISATION_ID),
    preprocessRequest: (request, clientOrigin) => {
      if (clientOrigin === "searchApiFetch") {
        if (typeof request.body === "string") {
          const body = JSON.parse(request.body);

          body.cq = `@source=="${COVEO_SOURCE}"`;

          // TODO - Further investigate the functionality of coveo.
          // for now this cleans up console and does not break if the object does not exist.
          if (body.fieldsToInclude != null) {
            body.fieldsToInclude.push("breadcrumbs");
            body.fieldsToInclude.push("docsguide");
            body.fieldsToInclude.push("docsversion");
          }

          request.body = JSON.stringify(body);
        }
      }
      return request;
    },
    search: {
      pipeline: "Customer",
    },
  },
});
