import { buildSearchBox } from "@coveo/headless";
import React, { useContext, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { SearchBoxContext } from "../contexts/SearchBoxContext";
import { useLocation } from "../contexts/useLocation";
import { headlessEngine } from "../coveo/Engine";
import { navigate } from "gatsby";
import { ResponsiveContext } from "../contexts/ResponsiveContext";
import { loadSearchActions, loadPaginationActions, loadQueryActions, loadSearchAnalyticsActions} from '@coveo/headless';

type Props = {
  hideResults?: boolean;
  isSearchScreen?: boolean;
};

const coveoSearchBox = buildSearchBox(headlessEngine);

const SearchBox = ({ isSearchScreen = false }: Props) => {
  const location = useLocation();

  const [controllerState, setControllerState] = useState(coveoSearchBox.state);
  const { responsiveState, dispatch } = useContext(ResponsiveContext);
  const { setShowSearchResult } =
    useContext(SearchBoxContext);

  const searchActions = loadSearchActions(headlessEngine);
  const paginationActions = loadPaginationActions(headlessEngine);
  const queryActions = loadQueryActions(headlessEngine);
  const analyticsActions = loadSearchAnalyticsActions(headlessEngine);

  const submitSearch = () => {
    if (responsiveState.isMenuActive) {
      dispatch({ type: "toggleMenu" });
    }
    if (!isSearchScreen) {
      navigate("/search");
    } else {
      setShowSearchResult(true);
      const query = coveoSearchBox.state.value;
      headlessEngine.dispatch(queryActions.updateQuery({ q: query }));

      headlessEngine.dispatch(paginationActions.updatePage(1));
      headlessEngine.dispatch(searchActions.executeSearch(analyticsActions.logSearchboxSubmit()));
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/search")) {
      submitSearch();
    }
    return coveoSearchBox.subscribe(() =>
      setControllerState(coveoSearchBox.state)
    );
  }, []);

  return (
    <div className="my-1 p-0  border-transparent rounded-2xl inline-block relative">
      <form
        className="flex items-center max-w-xs mx-auto"
        onSubmit={(event) => {
          event.preventDefault(); // Prevent the default form submission
          submitSearch(); // Call the submit function
        }}
      >
        <input
          type="text"
          id="simple-search"
          className=" h-8 border-none outline-none bg-very-light-grey dark:bg-medium-dark-grey border border-transparent text-medium-dark-grey dark:text-soft-blue-grey text-sm rounded-2xl block w-full pl-3 pr-10 py-2"
          placeholder="Search..."
          value={controllerState.value}
          onChange={(e) => coveoSearchBox.updateText(e.target.value)}
          required
        />
        <div className="absolute inset-y-0 dark:text-bright-white text-very-dark-grey right-0 flex items-center pr-3 pointer-events-none">
          {/* @ts-ignore */}
          <SearchOutlined />
        </div>
      </form>
    </div>
  );
};

export default SearchBox;
